import './Footer.css';

export default function Footer() {


  return (
    <footer style={{ color: 'white' }}>
      {'✨ A Sky Full of Stars ✨'}
    </footer>
  );
}
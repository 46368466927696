import React from 'react';
import './Loading.css';

export default function Loading() {
  return (
    <div className="loading-screen">
      <div className="infinity-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h3>Loading...</h3>
    </div>
  );
}